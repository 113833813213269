import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { overlay } from "../styles/util"
import { css } from "@emotion/core"

const WebDevelopmentPage = ({ data }) => (
  <Layout>
    <SEO title="Web Development" />
    <Img css={overlay} alt="Screenshot of lines of dense JavaScript code" fluid={data.file.childImageSharp.fluid} />
    <section css={styles.section}>
      <p>
        I've been doing front-end web development professionally since 2014. I've had the great fortune to work
        at a couple exceptional shops and for many great clients large and small. I specialize in HTML, CSS, JavaScript,
        and many of the tools, libraries, and frameworks that go along with them.
      </p>
      <p>
        I started my career out doing some Python work on enterprise-level Django applications and am now tackling
        even larger projects with PHP and Drupal. Which is to say, I have no particular allegiance to any one
        language or platform, so long as I can make cool stuff with it. I love and excel at picking up new technologies
        and hitting the ground running.
      </p>
    </section>
    <section css={styles.section}>
      <h4>Clients With Which I've Worked</h4>
      <ul>
        <li>Cox Media Group</li>
        <li>Syfy</li>
        <li>Grammy</li>
        <li>Mastercard</li>
        <li>Rand Corporation</li>
        <li>IBM</li>
      </ul>
    </section>
    <section css={styles.section}>
      <h4>Employment</h4>
      <ul>
        <li>Caktus Group: 2014-2016</li>
        <li>Lullabot: 2017-present</li>
      </ul>
    </section>
    <section css={styles.section}>
      <h4>Things I've Written</h4>
      <ul>
        <li><a href="https://www.lullabot.com/articles/eat-this-its-safe">Eat This, It’s Safe: How to Manage Side Effects with Redux-Saga</a></li>
        <li><a href="https://www.lullabot.com/articles/decoupled-drupal-getting-started-gatsby-and-jsonapi">Decoupled Drupal: Getting Started with Gatsby and JSON:API</a></li>
        <li><a href="https://www.lullabot.com/articles/hunters-habits-healthy-hands">Hunter's Habits for Healthy Hands</a></li>
      </ul>
    </section>
  </Layout>
)

const styles = {
  section: css`
    font-family: 'Open Sans', sans-serif;
    padding: 16px;
  `,
}

export default WebDevelopmentPage

export const query = graphql`
  query {
    file(relativePath: { eq: "code.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
